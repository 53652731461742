import React from 'react';
import * as styles from "./OurCompany.module.scss"
import SiteSection from "../../Atoms/SiteSection/SiteSection";
import SectionHeader from "../../Molecules/SectionHeader";
import {Col, Container, Row} from "../../Atoms/greeds";
import CardOne from "../../Molecules/CardOne";

const OurCompany = ({BigText, h2, cards}) => {

  const cardsOutput = cards.map((item, key) =>{
    return (
      <Col md={4} key={key}><CardOne Badge={item.count} Name={item.header} Desk={item.desc}/></Col>
    )
  })

  return (
    <SiteSection>
      <Container>
        <SectionHeader bigText={BigText} h2>{h2}</SectionHeader>
        <Row>
          {cardsOutput}
        </Row>
      </Container>
    </SiteSection>
  );
};

export default OurCompany;