import * as React from "react"
import Main from "../components/main"
import Header from "../components/header"
import PageHeader from "../components/Organism/PageHeader/PageHeader"

import Footer from "../components/Footer/Footer"
import "../scss/main.scss"
import OurServicesLite from "../components/OurServices/OurServicesLite"
import { Helmet } from "react-helmet"
import StartWork from "../components/forms/StartWork"
import Testimonials from '../components/Organism/testimonials/testimonials';
import Portfolio from "../components/Organism/Portfolio/Portfolio";
import Benefits from "../components/Organism/benefits/benefits";
import Cta from "../components/Organism/Cta/Cta";
import OurCompany from "../components/Organism/OurCompany/OurCompany";
// styles

// markup
const IndexPage = () => {
  const form = <StartWork/>;
  return (
    <>
      <Helmet
        title="Разработка сайтов и веб приложений, контекстная реклама" 
        meta={
          [
            {
              name:"description",
              content:"Агентство интернет маркетинга с комплексной заботой к вашему бизнесу"
            },
            {
              name:"freelancehunt",
              content:"0a85977833f8c32"
            }
          ]
        }
      >
        <script type="application/ld+json">
            {`
              {
                "@context": "http://schema.org/",
                "@type": "Article",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "https://androsovds.ru/"
                },
                "author": {
                  "@type": "Organization",
                  "name": "AndrosovDS"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "",
                  "logo": {
                    "@type": "ImageObject",
                    "url": ""
                  }
                },
                "headline": "Разработка сайтов и веб приложений, контекстная реклама",
                "image": "",
                "datePublished": ""
              }
            `}
        </script>
      </Helmet> 
      <Header/>
      <Main>
        <PageHeader h1={"Система услуг для развития вашего интернет маркетинга"} p={"Digital агентство с экспертизой в маркетинге и разработке сайтов"} modalButton сards={[
          {
            name:"Продумываем стратегии",
            desk:"Привлечение клиентов на базе глубокого анализа"
          },
          {
            name:"Прорабатываем всё что нужно",
            desk:"Сайты, посадочные страницы, контент"
          },
          {
            name:"Подключаем трафик",
            desk:"Контекстная и таргетированная реклама, SEO"
          },
          {
            name:"Корректируем действия",
            desk:"Анализируем показатели, корректируем рекламу и сайт"
          },
          ]} modalContent={form}/>
        <OurServicesLite col={2} cardsData={[
          {
            header:"Разработка сайтов",
            desc:"Создаем сайты сосредоточенные на том чтобы продавать ваши товары вашим клиентам",
            link:"../create-sites/",
          },
          {
            header:"Лендинги",
            desc:"Одностараничный сайт вашей компании, станет вашим надежным партнером по бизнесу",
            link:"../create-sites/landing-page/",
          },
          {
            header:"Корпоративные сайты",
            desc:"Разрабатываем корпоративные сайты так, чтобы ваш клиент не потерялся и купил нужный ему товар",
            link:"../create-sites/corporate/",
          },
          {
            header:"Интернет-магазины",
            desc:"Вы получите разработку интернет магазина, а покупатели удобную площадку для покупки ваших товаров",
            link:"../create-sites/e-commerce/",
          },
          {
            header:"Тех-поддержка сайтов",
            desc:"Сделаем всю сложную работу с сайтом за вас! Пока вы сосредоточенны на бизнесе",
            link:"../support/",
          },
          {
            header:"Контекстная реклама",
            desc:"Создание прибыльной рекламы выходящие за грани стандартной настройки",
            link:"../context-advertising/",
          },


        ]}
                         desc="Наши направления деятельности">Разрабатываем и продвигаем сайты на основе аналитики, а не предположений</OurServicesLite>
        <Benefits/>
        <Cta/>
        <Portfolio/>
        <OurCompany BigText="О компании" h2="Немного фактов о нашей команде"
                    cards={[
                      {
                        count:'2016',
                        header:'Год зарождения',
                        desc:'С 2016 года наш руководитель занимается интернет маркетингом и разработкой сайтов',
                      },
                      {
                        count:'87+',
                        header:'Проектов',
                        desc:'Создали и доработали более 67 веб-сайтов и 21 рекламную компанию',
                      },
                      {
                        count:'+...',
                        header:'Растем и развиваемся',
                        desc:'С 2020 года команда нашей студии стала расширяться от 1 до 13 человек. И планирует расти дальше',
                      },
                      ]}/>

      </Main>
      <Footer/>
    </>
  )
}

export default IndexPage